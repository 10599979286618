.aside-with-image {
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  &::before {
    background-size: contain;
    background-repeat: repeat;
    background-image: url('/images/components/aside-with-image/background.jpg');
  }

  &.aside-with-image--centered {
    &::after {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &__content {
    &--centered {
    }
  }
}
