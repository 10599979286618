.MuiGrid-container {
  &.cards-grid {
    // border: 1px solid red;
  }

  .cards-grid__item {
  }

  .card {
    border: 10px solid green;
    &__image {
    }
  }

  .card-content {
    &__heading {
    }

    &__description {
    }
  }

  .card-actions {
  }
  .card-link {
  }
}
