.carousel {
  // border: 5px solid green !important;
  // border: '10px solid red';
}

.swiper {
  // border: 5px solid green !important;
  width: 100%;
  height: 400px;
  // height: 'inherit';
  box-sizing: border-box;
}

.swiper-slide {
  // border: 1px solid red !important;
  // background: cyan;
  box-sizing: border-box;
  text-align: center;
  // font-size: 18px;
  // height: 200px !important;

  /* Center slide text vertically */
  // display: -webkit-box;
  // display: -ms-flexbox;
  // display: -webkit-flex;
  // display: flex;
  // -webkit-box-pack: center;
  // -ms-flex-pack: center;
  // -webkit-justify-content: center;
  // justify-content: center;
  // -webkit-box-align: center;
  // -ms-flex-align: center;
  // -webkit-align-items: center;
  // align-items: center;
}

.swiper-slide img {
  // border: 5px solid green !important;
  // display: block;
  // width: 100%;
  // height: 200px !important;
  // object-fit: cover;
}

.swiper-vertical {
  .swiper-button-next,
  .swiper-button-prev {
    left: 50%;
    transform: rotate(90deg);
    transform-origin: left center;
  }

  .swiper-button-prev {
    top: 10px;
  }

  .swiper-button-next {
    top: auto;
    bottom: 10px;
  }

  &.swiper--large {
    // border: 10px solid red;

    .swiper-button-next,
    .swiper-button-prev {
      left: initial;
      right: 0;
      transform: rotate(90deg);
      transform-origin: left center;
    }

    .mui-image-wrapper {
      // justify-content: flex-start !important;
    }

    .mui-image-img {
      // width: auto !important;
    }
  }
}
